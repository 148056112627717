export const linkResolver = (doc: {
  type: string | null;
  uid: string | null;
}) => {
  /**
   * For preview renderings
   */
  if (!doc?.type) return "";

  let path = doc.uid?.replace(/_/g, "/");

  if (path && !path.endsWith("/")) path = `${path}/`;

  switch (doc.type) {
    case "page":
    case "page_script":
      if (path === "accueil/") return "/";

      return `/${path}`;

    case "page_landing":
      return `/lp/${path}`;

    default:
      return "/";
  }
};
