import { Box } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { adjustSvg } from "src/lib/svg";

/**
  colorChanges is an array of tuples. First element of a tuple is the color to be replaced by the second element
 */
type SvgOrImgProps = {
  imageFile: Gatsby.Maybe<Pick<Gatsby.PrismicElementImageDataImageFileImageType, "gatsbyImageData" | "alt"> & {
    readonly localFile: Gatsby.Maybe<Pick<Gatsby.File, "text">>;
  }>
  isBox?: boolean;
  size: number;
  additionalBoxProps?: Record<string, string | number | Record<string, string | number>>;
  colorChanges?: [string, string][];
};

/**
 * We try to render SVG from `localFile` for cost reduction purposes
 * If it's not available, we render the image from `gatsbyImageData` as a fallback
 */
const SvgOrImg: FC<SvgOrImgProps> = ({ additionalBoxProps, colorChanges, imageFile, isBox, size }) => {
  const svg = adjustSvg(imageFile?.localFile?.text, size, colorChanges);

  if (!svg) {
    if (isBox) {
      return (
        <Box
          as={GatsbyImage}
          image={imageFile?.gatsbyImageData}
          alt={imageFile?.alt ?? ""}
          {...additionalBoxProps}
        />
      );
    }

    return (
      <GatsbyImage
        image={imageFile?.gatsbyImageData}
        alt={imageFile?.alt || ""}
      />
    );
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: svg }} />
  );
};

export { SvgOrImg };
