import { Container } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainerProps } from "./types";

const BlockContainer: FC<BlockContainerProps> = ({
  children,
  wrapperContainerProps,
  ...props
}) => (
  <Container size="full" {...wrapperContainerProps}>
    <Container
      size="block"
      textAlign="center"
      centerContent
      {...props}
    >
      {children}
    </Container>
  </Container>
);

export default BlockContainer;
