import {
  LEAD_BUTTON_CLICKED,
  LEAD_FROM_TARIFS_BUTTON_CLICKED,
  LEAD_FROM_TARIFS_PENDING_REFERRAL_BUTTON_CLICKED,
  LEAD_PENDING_REFERRAL_BUTTON_CLICKED,
  LEAD_WITHOUT_REFERRAL_BUTTON_CLICKED,
  LEAD_WITH_REFERRAL_BUTTON_CLICKED
} from "./constants";
import { isInternalLink } from "./properties";
import { ClickInfo } from "./types";

/**
 * Returns the appropriate event name based on the use-case
 * for further analysis down the road (e.g. with Meta)
 */
const getNewAccountEventName = (link: string, clickInfo: ClickInfo) => {
  const {
    isReferralFlow: isReferralFlowTemp,
    isReferralValid,
    fromLocation
  } = clickInfo;

  const { pathname } = fromLocation || {};
  const isTarifsPage = pathname === '/tarifs' || pathname?.startsWith('/tarifs/');
  const isReferralLink = link.startsWith('/referral/');
  const isReferralFlow = isReferralLink || isReferralFlowTemp;

  if (isReferralLink) {
    return isTarifsPage
      ? LEAD_FROM_TARIFS_PENDING_REFERRAL_BUTTON_CLICKED
      : LEAD_PENDING_REFERRAL_BUTTON_CLICKED;
  }

  if (!isReferralFlow || isInternalLink(link)) {
    return isTarifsPage ? LEAD_FROM_TARIFS_BUTTON_CLICKED : LEAD_BUTTON_CLICKED;
  }

  const name = isReferralValid ? LEAD_WITH_REFERRAL_BUTTON_CLICKED : LEAD_WITHOUT_REFERRAL_BUTTON_CLICKED;
  return name;
};

export { getNewAccountEventName };
