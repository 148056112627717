import { ButtonProps, LinkProps } from "@chakra-ui/react";
import { isValidElement } from "react";
import { PrismicLink } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";
import WistiaLink from "src/components/WistiaLink";
import { Serializer, SerializerSelector } from "../types";
import { getComponentFromSelector } from "../utils";

export enum ButtonLabel {
  "button-primary" = "button-primary",
  "button-secondary" = "button-secondary",
  "button-secondary-arrow" = "button-secondary-arrow",
  "button-link" = "button-link",
}

const hyperLinksSelector: SerializerSelector<ButtonProps & LinkProps> = [
  {
    condition: ({ children }) =>
      children.some(
        (child) =>
          isValidElement(child) &&
          child.props.className === ButtonLabel["button-primary"],
      ),
    component: ({ content, key, element, overrideProps, hasArrow }) => (
      <ShiButton
        as={PrismicLink}
        variant="primary"
        key={key}
        link={element.data}
        hasArrow={hasArrow !== false}
        className="btn-primary"
        {...overrideProps}
      >
        {content}
      </ShiButton>
    ),
  },

  {
    condition: ({ children }) =>
      children.some(
        (child) =>
          isValidElement(child) &&
          child.props.className === ButtonLabel["button-secondary"],
      ),
    component: ({ content, key, element, overrideProps, hasArrow }) => (
      <ShiButton
        as={PrismicLink}
        variant="secondary"
        key={key}
        link={element.data}
        hasArrow={hasArrow !== false}
        className="btn-secondary"
        {...overrideProps}
      >
        {content}
      </ShiButton>
    ),
  },

  {
    condition: ({ children }) =>
      children.some(
        (child) =>
          isValidElement(child) &&
          child.props.className === ButtonLabel["button-link"],
      ),
    component: ({ content, key, element, overrideProps }) => (
      <ShiButton
        as={PrismicLink}
        variant="link"
        key={key}
        link={element.data}
        className="btn-link"
        {...overrideProps}
      >
        {content}
      </ShiButton>
    ),
  },

  {
    condition: ({ children }) =>
      children.some((child) => isValidElement(child)),
    component: ({ content, key, element, overrideProps }) => (
      <PrismicLink
        key={key}
        link={element.data}
        variant="link-blog"
        {...overrideProps}
      >
        {content}
      </PrismicLink>
    ),
  },
];

/**
 * This is a variation of the recommended regex :
 * @see https://wistia.com/support/developers/oembed#the-regex
 *
 * Specifically, it adds a named capturing group for the ID of the video.
 */
const WISTIA_VIDEO_MATCH = /https?:\/\/[^.]+\.(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(?<id>\w+)/;

/**
 * Hyperlinks can be rendered as buttons, or links.
 */
export const hyperlink: Serializer<ButtonProps & LinkProps> = (args) => {
  const externalURL: string | undefined = args.element.data?.url;
  const wistiaId = externalURL?.match(WISTIA_VIDEO_MATCH)?.groups?.id;

  return wistiaId ? (
    <WistiaLink wistiaId={wistiaId} key={args.key}>
      {getComponentFromSelector(hyperLinksSelector, {
        ...args,
        element: {
          ...args.element,
          data: {
            link_type: "Web",
            url: "#",
          },
        },
      }) ?? null}
    </WistiaLink>
  ) : (
    getComponentFromSelector(hyperLinksSelector, args) ?? null
  );
};
