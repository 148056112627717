import { FC, useRef } from "react";

import { DidomiSDK, IDidomiObject } from "@didomi/react";
import Context from "./Context";
import { logEvent } from "src/lib/analytics";

const SEGMENT_IO = "Segment.io";
const EVENT_CONSENT_CHANGED = "Segment Integrations Preference Updated";

let integrations: Record<string, boolean> = { All: false };

export const DIDOMI_CONFIG_FALLBACK = {
  always_on: [
    "BigQuery_2",
    "Google Tag Manager"
  ],
  "Segment Mappings": {
    "facebook-A8enZGfW": [
      "Facebook Pixel",
      "Facebook Pixel Server Side",
      "Facebook Conversions API - Marketing Website",
      "Facebook Conversions API"
    ],
    "google": [
      "DoubleClick Floodlight",
      "Google AdWords New",
      "Google Ads (Gtag)"
    ],
    "googleana-4TXnJigR": [
      "Google Analytics",
      "Google Analytics 4",
      "Actions Google Analytics 4"
    ],
    "intercom-U4Zwpdz8": ["Intercom"],
    "linkedin-ZtrLMW7M": ["Linkedin Insight Tag"],
    "matomo-J77TAWAM": ["Matomo"],
    "microsoft": ["Bing Ads"],
    "mixpanel": ["Mixpanel"],
    "segment": [SEGMENT_IO],
    "snapinc-yhYnJZfT": ["Snapchat Conversions API"],
    "tiktok-KZAUQLZ9": ["TikTok"],
    twitter: ["Twitter"],
  },
};

const Cookies: FC = ({ children }) => {
  const segmentLoaded = useRef(false);
  const didomiRef = useRef<null | IDidomiObject>(null);

  const onCustomizeConsent = () => {
    if (didomiRef.current) {
      didomiRef.current.preferences?.show();
    }
  };

  const updateIntegrationsObject = (cancelConsent = false) => {
    const didomi = didomiRef.current;

    if (didomi === null) {
      return;
    }

    const status = didomi.getCurrentUserStatus();

    const alwaysOn = DIDOMI_CONFIG_FALLBACK.always_on;
    const mapping = DIDOMI_CONFIG_FALLBACK["Segment Mappings"];

    integrations = { All: false };

    alwaysOn.forEach((integration: string) => {
      integrations[integration] = cancelConsent ? false : true;
    });

    if (status && status.vendors) {
      const { vendors } = status;
      const vendorsKeys = Object.keys(vendors);

      vendorsKeys.forEach((vendorApiId: string) => {
        const vendorValue = vendors[vendorApiId];

        if (vendorValue && [false, true].includes(vendorValue.enabled)) {
          mapping[vendorApiId]?.forEach((integration: string) => {
            integrations[integration] = cancelConsent ? false : vendorValue.enabled;
          });
        }
      });
    }
  };

  const loadSegmentAndDestinations = () => {
    updateIntegrationsObject();

    if (integrations[SEGMENT_IO]) {
      segmentLoaded.current = true;
      window.analytics?.load(process.env.GATSBY_SEGMENT_WRITE_KEY || "", {
        integrations,
      });

      // As Segment is loaded, we can send a consent changed event
      const event = {
        name: EVENT_CONSENT_CHANGED,
        properties: { integrations }
      };

      logEvent(event);
    }
  };

  const onDidomiReady = (didomi: IDidomiObject) => {
    didomiRef.current = didomi;

    const shouldUserStatusBeCollected = didomi.shouldUserStatusBeCollected();

    // consent already has been collected, we can load segment
    if (!shouldUserStatusBeCollected) {
      loadSegmentAndDestinations();
      return;
    }
  };

  return (
    <Context.Provider
      value={{
        openCookiesSettings: onCustomizeConsent,
      }}
    >
      {children}
      <DidomiSDK
        apiKey={process.env.GATSBY_DIDOMI_API_KEY ?? undefined}
        onReady={onDidomiReady}
        onConsentChanged={() => {
          if (segmentLoaded.current) {
            /**
             * Segment has already been loaded, we will have to refresh the page
             * If the user decides to remove Segment we need to cancel all consent for good measure
             */
            updateIntegrationsObject();

            // We send a last consent changed event before Segment gets disabled
            if (!integrations[SEGMENT_IO]) {
              updateIntegrationsObject(true);

              const event = {
                name: EVENT_CONSENT_CHANGED,
                properties: { integrations }
              };

              logEvent(event, () => document.location.reload());
            } else {
              // Sending a new consent changed event will happen after the reload
              document.location.reload();
            }
          } else {
            loadSegmentAndDestinations();
          }
        }}
        config={{
          tagManager: {
            provider: "gtm",
          },
        }}
      />
    </Context.Provider>
  );
};

export default Cookies;
