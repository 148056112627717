import { Element, PrismicRichText, PrismicRichTextProps } from "@prismicio/react";
import { FC } from "react";
import serializer, { HTMLSerializerWithOverrides } from "./serializer";
import { Serializer } from "./types";
import { hotfixLabels } from "./utils";

const Renderer: FC<
  Omit<PrismicRichTextProps, "field"> & {
    field?: Pick<Gatsby.PrismicStructuredTextType, "raw">;
    overrides?: Partial<Record<Element, Serializer>>;
    components?: HTMLSerializerWithOverrides;
  }
> = ({ field, components = serializer, overrides, ...props }) =>
  field ? (
    <PrismicRichText
      field={hotfixLabels(field.raw)}
      components={(...args) => components(...args, overrides)}
      {...props}
    />
  ) : null;

export default Renderer;
