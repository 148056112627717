export const getPreviousIndex = (index: number, length: number) => {
  if (length < 2) return -1;

  return ((index - 1) + length) % length;
}

export const getNextIndex = (index: number, length: number) => {
  if (length < 2) return -1;

  return (index + 1) % length;
}
