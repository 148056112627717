const isPersistentParam = (key: string, utmOnly = false) => {
  const utmPrefix = 'utm_';

  const prefixesArray = utmOnly ? [utmPrefix] : ['_g', utmPrefix];
  const isPersistent = prefixesArray.some((prefix) => key.startsWith(prefix));

  return isPersistent;
};

const hasPersistentParam = (search?: string) => {
  if (!search) {
    return false;
  }

  const params = new URLSearchParams(search);
  for (let [key] of params.entries()) {
    if (isPersistentParam(key, true)) {
      return true;
    }
  }

  return false;
};

export { hasPersistentParam, isPersistentParam };
