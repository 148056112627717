import {
  ListItem,
  ListProps,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Serializer } from "../types";

export const list: Serializer<ListProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <UnorderedList key={key} marginBottom="space-24" {...overrideProps}>
    {children}
  </UnorderedList>
);

export const oList: Serializer<ListProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <OrderedList key={key} marginBottom="space-24" {...overrideProps}>
    {children}
  </OrderedList>
);

/**
 * Note : this one is used both for list and oList items.
 */
export const listItem: Serializer = ({ children, key, overrideProps }) => (
  <Text as={ListItem} key={key} {...overrideProps}>
    {children}
  </Text>
);
