import { Heading } from "@chakra-ui/react";
import { HeadingProps } from "@chakra-ui/react";
import { Serializer } from "../types";

export const heading1: Serializer<HeadingProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <Heading
    as="h1"
    variant="heading-1"
    key={key}
    marginBottom={{ base: "space-16", md: "space-24" }}
    {...overrideProps}
  >
    {children}
  </Heading>
);

export const heading2: Serializer<HeadingProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <Heading
    as="h2"
    variant="heading-2"
    key={key}
    marginBottom={{ base: "space-16", md: "space-24" }}
    {...overrideProps}
  >
    {children}
  </Heading>
);

export const heading3: Serializer<HeadingProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <Heading
    as="h3"
    variant="heading-3"
    key={key}
    marginBottom={{ base: "space-16", md: "space-24" }}
    {...overrideProps}
  >
    {children}
  </Heading>
);

export const heading4: Serializer<HeadingProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <Heading as="h4" variant="heading-4" key={key} {...overrideProps}>
    {children}
  </Heading>
);

export const heading5: Serializer<HeadingProps> = ({
  children,
  key,
  overrideProps,
}) => (
  <Heading as="h5" variant="heading-4" key={key} {...overrideProps}>
    {children}
  </Heading>
);

export const heading6: Serializer<HeadingProps> = () => null;
