import { Element } from "@prismicio/react";
import { Serializer } from "../types";
import {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
} from "./headings";
import { hyperlink } from "./hyperlink";
import { image } from "./image";
import { list, listItem, oList } from "./list";
import paragraph from "./paragraph";
import { label, span, strong } from "./typography";

/**
 * Null values are passed through to the default PrismicRichText renderer.
 */
const elements: Record<Element, Serializer<any>> = {
  [Element.paragraph]: paragraph,
  [Element.preformatted]: () => null,
  [Element.strong]: strong,
  [Element.span]: span,
  [Element.em]: () => null,

  [Element.heading1]: heading1,
  [Element.heading2]: heading2,
  [Element.heading3]: heading3,
  [Element.heading4]: heading4,
  [Element.heading5]: heading5,
  [Element.heading6]: heading6,

  [Element.image]: image,
  [Element.hyperlink]: hyperlink,
  [Element.label]: label,
  [Element.embed]: () => null,

  [Element.list]: list,
  [Element.listItem]: listItem,
  [Element.oList]: oList,
  [Element.oListItem]: listItem,
};

export default elements;
