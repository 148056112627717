import { AspectRatio, Box } from "@chakra-ui/react";
import { lazy, FC, useCallback, Suspense } from "react";
import { useInView } from "react-intersection-observer";
import { ElementLottieFileProps } from "./types";

const LottieComponent = lazy(() => import("./LottieComponent"));

const isSSR = typeof window === "undefined";

const ElementLottieFile: FC<ElementLottieFileProps> = ({
  width,
  height,
  url,
}) => {
  const { ref: createdRef, inView: hasBeenCreated } = useInView({
    rootMargin: "200px 0px 200px 0px",
    triggerOnce: true,
  });
  const { ref: inViewRef, inView } = useInView({
    rootMargin: "50px 0px 50px 0px",
  });

  const ref = useCallback(
    (node: HTMLDivElement) => {
      createdRef(node);
      inViewRef(node);
    },
    [createdRef, inViewRef]
  );

  return (
    <AspectRatio ref={ref} ratio={width && height ? width / height : 1}>
      <Box>
        {isSSR ? null : (
          <Suspense fallback={<Box />}>
            {hasBeenCreated && <LottieComponent inView={inView} url={url} />}
          </Suspense>
        )}
      </Box>
    </AspectRatio>
  );
};

export default ElementLottieFile;
