import { FC } from "react";
import LottieAnimation from "src/components/LottieAnimation";

const ElementAnimation: FC<Gatsby.ElementAnimationFragment> = ({ data }) => {
  if (!data?.animation_file?.url) return null;

  return (
    <LottieAnimation
      url={data.animation_file.url}
      width={data.width}
      height={data.height}
    />
  );
};

export default ElementAnimation;
