/**
 * Custom event names must be strings, and cannot exceed 50 characters in length.
 * @see https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
 */

import { PageTemplateMatcher } from "./types";

// 
export const LEAD_BUTTON_CLICKED = 'Lead Button Clicked';
export const LEAD_PENDING_REFERRAL_BUTTON_CLICKED = 'Lead Pending Referral Button Clicked';
export const LEAD_FROM_TARIFS_BUTTON_CLICKED = 'Lead From Tarifs Button Clicked';
export const LEAD_FROM_TARIFS_PENDING_REFERRAL_BUTTON_CLICKED = 'Lead From Tarifs Pending Referral Button Clicked';
export const LEAD_WITHOUT_REFERRAL_BUTTON_CLICKED = 'Lead Without Referral Button Clicked';
export const LEAD_WITH_REFERRAL_BUTTON_CLICKED = 'Lead With Referral Button Clicked';

// Standard monthly prices
export const SHINE_BASIC_PRICE = 7.9;
export const SHINE_PLUS_PRICE = 14.9;
export const SHINE_PRO_PRICE = 29;
export const SHINE_BUSINESS_PRICE = 99;

export const NOS_OFFRES_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Nos offres',
  paths: [
    '/architecte/',
    '/artisans/',
    '/btp/',
    '/commerce-restaurant/',
    '/compte-pro-avocat/',
    '/creation-entreprise-decouvrir/',
    '/creation-entreprise/',
    '/creation-eurl/',
    '/declaration-auto-entrepreneur/',
    '/depot-de-capital/',
    '/e-commerce/',
    '/entreprises/',
    '/freelances/',
    '/independants/',
    '/paramedical/',
    '/professions-liberales/',
    '/startups/'
  ]
};

export const FONCTIONNALITES_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Fonctionnalités',
  paths: [
    '/comptabilite/',
    '/compte-pro/',
    '/depenses-equipe/',
    '/depot-especes/',
    '/encaissements/',
    '/extras-assurances/',
    '/facturation/',
    '/financement/',
    '/nos-cartes/',
    '/parrainage/',
    '/pret-credit/',
    '/securite/',
    '/sumup/'
  ]
};

export const RESSOURCES_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Ressources',
  paths: [
    '/changement-climatique/',
    '/comparatif-comptes-pro/',
    '/newsletter-lanterne/',
    '/shiny-webinars/',
    '/toolbox/'
  ]
};

export const TARIFS_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Tarifs',
  paths: ['/tarifs/']
};

export const LANDING_PAGE_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Landing Page',
  paths: ['/lp/']
};

export const A_PROPOS_PATHS: PageTemplateMatcher = {
  pageTemplate: 'À propos',
  paths: [
    '/contacter-shine/',
    '/lp/offre/experts-comptables/',
    '/mission/',
    '/presse/'
  ]
};

export const LEGAL_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Légal',
  paths: [
    '/lp/cgu-ep-en-clair/',
    '/mentions-legales/',
    '/privacy/fr/'
  ]
};

export const PARRAINAGE_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Parrainage',
  paths: ['/referral/']
};

export const DEMO_PATHS: PageTemplateMatcher = {
  pageTemplate: 'Demo',
  paths: ['/demo/', '/demo-']
};

export const ALL_PATHS_MATCHERS: PageTemplateMatcher[] = [
  NOS_OFFRES_PATHS,
  FONCTIONNALITES_PATHS,
  RESSOURCES_PATHS,
  TARIFS_PATHS,
  LEGAL_PATHS,
  A_PROPOS_PATHS,
  LANDING_PAGE_PATHS,
  PARRAINAGE_PATHS,
  DEMO_PATHS
];

export const SUB_CAT_1 = new Map([
  // Nos offres
  ['/architecte/', 'Architectes'],
  ['/artisans/', 'Artisans'],
  ['/btp/', 'BTP'],
  ['/commerce-restaurant/', 'Restaurants'],
  ['/compte-pro-avocat/', 'Avocat·es'],
  ['/creation-entreprise/', "Création d'entreprise"],
  ['/depot-de-capital/', 'Dépôt de capital'],
  ['/e-commerce/', 'E-commerce'],
  ['/entreprises/', 'Pour les entreprises (TPE/PME)'],
  ['/freelances/', 'Freelances'],
  ['/independants/', 'Pour les indépendant·es'],
  ['/paramedical/', 'Paramédical'],
  ['/professions-liberales/', 'Professions libérales'],
  ['/startups/', 'Pour les starts-ups et agences'],
  // Fonctionnalités
  ['/comptabilite/', 'Comptabilité simplifiée'],
  ['/compte-pro/', 'Compte pro 100 % en ligne'],
  ['/depenses-equipe/', 'Gestion des dépenses en équipes'],
  ['/depot-especes/', "Dépôt d'espèces"],
  ['/encaissements/', 'Encaissements'],
  ['/extras-assurances/', 'Extras et assurances'],
  ['/facturation/', 'Outil de facturation intégré'],
  ['/financement/', 'Une solution de financement pour chaque entreprise'],
  ['/nos-cartes/', 'Cartes bancaires'],
  ['/parrainage/', 'Parrainage'],
  ['/pret-credit/', 'Prêt professionnel'],
  ['/securite/', 'Sécurité'],
  ['/sumup/', 'Terminal de paiement (TPE)'],
  // Ressources
  ['/changement-climatique/', 'Agir contre le changement climatique'],
  ['/comparatif-comptes-pro/', 'Comparatif banque pro'],
  ['/newsletter-lanterne/', 'La Lanterne - Newsletter'],
  ['/shiny-webinars/', 'Workshops et vidéos'],
  ['/toolbox/', 'Boîte à outils'],
  // À propos
  ['/contacter-shine/', 'Nous contacter'],
  ['/mission/', 'Construire une banque responsable'],
  ['/lp/offre/experts-comptables/', 'Devenir expert-comptable partenaire'],
  ['/presse/', 'Espace presse'],
  // Légal
  ['/lp/cgu-ep-en-clair/', "Conditions d'utilisation"],
  ['/mentions-legales/', 'Mentions légales'],
  ['/privacy/fr/', 'Protection des données']
]);

export const EMPTY = 'Empty';
export const UNKNOWN = 'Unknown';
