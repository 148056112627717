import { Box, BoxProps, ChakraComponent } from "@chakra-ui/react";

const Card: ChakraComponent<"div", { hoverable?: boolean }> = ({
  hoverable = true,
  ...props
}) => (
  <Box
    backgroundColor="white"
    borderRadius="lg"
    padding="space-24"
    transitionProperty="all"
    transitionDuration="300ms"
    transitionTimingFunction="ease-out"
    _hover={
      hoverable
        ? {
            shadow: "sm-light-hover",
            transform: "translateY(-2px)",
          }
        : undefined
    }
    {...(props as BoxProps)}
  />
);

export default Card;
