import { FC, useCallback } from "react";

import Context from "./Context";
import writeCookie from "./writeCookie";
import { VisitorInfo } from "./types";
import { equals } from "ramda";
import { useLocalStorage } from "react-use";

const DEFAULT_VISITOR_CONTEXT = {
  countryCode: "FR", // User country code
  referrerCode: null, // Used to identify referrer if exists
  referrerName: null, // Used to display referrer name if exists
  affiliateSource: null, // Used to track user coming from an affiliate
  affiliateLandingHref: null, // Used to track full information about affiliate landing page
  search: null, // The latest search query params the visitor landed on the site with
};

const VisitorContext: FC = ({ children }) => {
  const [visitorContext, setVisitorContext] = useLocalStorage<VisitorInfo>(
    "VisitorContext",
    DEFAULT_VISITOR_CONTEXT,
  );

  const updateVisitorContext = useCallback(
    (updates: Partial<VisitorInfo>) => {
      const updated = {
        ...(visitorContext ?? DEFAULT_VISITOR_CONTEXT),
        ...updates,
      };

      if (!equals(visitorContext, updated)) setVisitorContext(updated);

      const {
        referrerCode,
        referrerName,
        affiliateSource,
        affiliateLandingHref,
        search,
      } = updated;

      writeCookie({
        referrerCode,
        referrerName,
        affiliateSource,
        affiliateLandingHref,
        search,
      });
    },
    [setVisitorContext, visitorContext],
  );

  return (
    <Context.Provider
      value={{
        ...(visitorContext ?? DEFAULT_VISITOR_CONTEXT),
        updateVisitorContext,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default VisitorContext;
