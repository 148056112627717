import { FC } from "react";
import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from "@chakra-ui/react";

import * as ICONS from "./icons";

export type ShiIconName = keyof typeof ICONS;

export type ShiIconProps = ChakraIconProps & {
  name: ShiIconName;
  fill?: string;
  boxSize?: number | string;
};

const ShiIcon: FC<ShiIconProps> = ({ name, fill, ...props }) => {
  const refinedName = name?.replace(/-/g, "") as keyof typeof ICONS;

  const IconComponent = ICONS[refinedName];

  if (!IconComponent) {
    console.warn(`Unknown icon : ${name}`);

    return null;
  }

  return (
    <ChakraIcon as={IconComponent} {...props} fill={fill ?? "currentColor"} />
  );
};

export default ShiIcon;
