import { Image, ImageProps } from "@chakra-ui/react";
import { Serializer } from "../types";

/**
 * Sensible default value
 */
const DEFAULT_SIZE = 1340;

export const image: Serializer<
  ImageProps & { width?: number; height?: number }
> = ({ element, overrideProps: { width, height, ...overrideProps } = {} }) => {
  const url = (element.url as string)?.includes("images.prismic.io")
    ? `${element.url}&w=${width ? width * 2 : DEFAULT_SIZE}&h=${
        height ? height * 2 : DEFAULT_SIZE
      }`
    : undefined;

  return (
    <Image
      src={url}
      boxSize={`${width}px`}
      marginBottom="space-16"
      {...overrideProps}
    />
  );
};
