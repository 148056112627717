import { Element } from "@prismicio/react";
import { ReactNode } from "react";
import elements from "./elements";
import { Serializer } from "./types";

export type HTMLSerializerWithOverrides = (
  type: Element,
  element: any,
  content: string,
  children: ReactNode[],
  key: string,
  overrides?: Partial<Record<Element, Serializer>>
) => ReactNode | null;

/**
 * This function takes values describing a Rich Text, coming from prismic.io,
 * and returns a React Node to render it in the browser.
 */
const serializer: HTMLSerializerWithOverrides = (
  type,
  element,
  content,
  children,
  key,
  overrides
) =>
  ({ ...elements, ...overrides }[type]({
    type,
    element,
    content,
    children,
    key,
  }));

export default serializer;
