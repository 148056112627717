import { ButtonLabel } from "./elements/hyperlink";
import { SerializerArgs, SerializerSelector } from "./types";

const NON_BREAKING_SPACE_REG = /\u00a0/g;
const SPACE_PUNCTUATION_END_REG = / ([:;?!%€«»]|\bHT\b)/g;
const SPACE_PUNCTUATION_BETWEEN_REG = /(\d) (\d)/g;

/**
 * Given a selector (a table of components, each linked to a condition),
 * returns the component which matches first, or undefined.
 */
export const getComponentFromSelector = (
  selector: SerializerSelector<any>,
  args: SerializerArgs<any>
) => selector.find(({ condition }) => condition(args))?.component(args);

/**
 * Applies custom rules for adding non-breaking spaces as needed.
 */
export const applyNbsp = (text: string) => text
  .replace(NON_BREAKING_SPACE_REG, " ")
  .replace(SPACE_PUNCTUATION_END_REG, "\u00a0$1")
  .replace(SPACE_PUNCTUATION_BETWEEN_REG, "$1\u00a0$2");

export const tweakBadgeName = (badge: string) => {
  if (!badge) {
    return '';
  }

  if (badge === 'badge-green') {
    return 'badge-orange';
  }

  return badge;
};

export const getBadgeColor = (badge: string) => {
  if (!badge) {
    return '';
  }

  return badge.replace('badge-', '');
};

/**
 * Grab a cup of coffee before you start to read this.
 *
 * To represent buttons in Prismic, we use hyperlinks - that's normal.
 * To add information to text or hyperlinks, Prismic provides "labels", which highlight the text in the admin UI.
 *
 * We use the label `button-primary` on hyperlinks to specify that this hyperlink should display as a primary button.
 *
 * That could be all, and it works perfectly - EXCEPT in Firefox.
 * In most browsers, the label is wrapped in the hyperlink (so PrismicRichText.field works as expected)
 * But in Firefox, the hyperlink is wrapped in the label - which destroys our logic.
 *
 * I spent enough time trying to understand why, and have to move on now, so what this function does is :
 * - Check for labels that represent our buttons.
 *
 * - Increment by one their starting point. We don't care about their exact placement anyway :
 *   we display a hyperlink as a button **if ANY of his children has the corresponding label**.
 *
 * - This new label is now, even in Firefox, the children of its corresponding hyperlink ! Enjoy :)
 */
export const hotfixLabels = (raw?: any[]) =>
  raw?.map((paragraph: any) => {
    // An early return to prevent unnecessary spreads,
    // because we have so much paragraphs, and so few of them contain button labels.
    if (!paragraph.spans?.some((span: any) => span.data?.label in ButtonLabel))
      return paragraph;

    return {
      ...paragraph,
      spans: paragraph.spans.map((span: any) =>
        // If this span is a label...
        span.type === "label" &&
        // ...and this label represent a button
        span.data?.label in ButtonLabel
          ? // Increment it starting point by one to make sure it's considered as a **child**
            // of its corresponding hyperlink
            {
              ...span,
              start: span.start + 1,
            }
          : span
      ),
    };
  });
