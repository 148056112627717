import React, { FC, Fragment, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { MotionBox } from "src/atoms";

const isClient = typeof window !== "undefined";

const getWistiaTag = (wistiaId: string) => `wistia-${wistiaId}`;

/**
 * This component, given a Wistia video ID and a ReactElement (that should resolve to a `<a>` tag),
 * will wrap the link inside a `span` with proper Wistia classNames,
 * and inject the necessary Wistia scripts inside `<head>`.
 */
const WistiaLink: FC<{ wistiaId: string }> = ({ wistiaId, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Wait for the Wistia SDK to find the button and override it.
   * We'll use it to animate the entry AFTER Wistia has overriden it.
   */
  useEffect(() => {
    window.wistiaInitQueue.push((Wistia: any) =>
      Wistia.api.onFind?.(getWistiaTag(wistiaId), () => {
        setIsLoaded(true);
      })
    );
  }, [wistiaId]);

  return (
    <Box minHeight="48px" as="span" display="block">
      {isClient && (
        <Fragment>
          <Helmet>
            {/* The Wistia SDK. */}
            {/* Multiple scripts with the same src don't trigger multiple requests, so that's cool. */}
            <script
              src="https://fast.wistia.com/assets/external/E-v1.js"
              async
            />

            {/* The specific JSON data for this video. */}
            <script
              src={`https://fast.wistia.com/embed/medias/${wistiaId}.jsonp`}
              async
            />
          </Helmet>

          {/* Animate in the button AFTER Wistia has seen and replaced it. */}
          <MotionBox
            as="span"
            display="block"
            initial={false}
            animate={{
              opacity: isLoaded ? 1 : 0,
              y: isLoaded ? 0 : 10,
            }}
          >
            <Box
              as="span"
              position="relative"
              display="inline"
              id={getWistiaTag(wistiaId)}
              className={[
                "wistia_embed",
                `wistia_async_${wistiaId}`,
                "popover=true",
                "popoverContent=link",
              ].join(" ")}
            >
              {children}
            </Box>
          </MotionBox>
        </Fragment>
      )}
    </Box>
  );
};

export default WistiaLink;
