import { Center, CenterProps } from "@chakra-ui/react";
import { FC } from "react";

const IconBox: FC<CenterProps> = ({ children, ...rest }) => {
  return (
    <Center
      bg="white"
      w="56px"
      h="56px"
      flexShrink="0"
      borderRadius="lg"
      {...rest}
    >
      {children}
    </Center>
  );
};

export default IconBox;
