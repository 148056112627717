// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-inscription-tsx": () => import("./../../../src/pages/inscription.tsx" /* webpackChunkName: "component---src-pages-inscription-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-templates-landing-index-tsx": () => import("./../../../src/templates/Landing/index.tsx" /* webpackChunkName: "component---src-templates-landing-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/Page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-page-script-index-tsx": () => import("./../../../src/templates/PageScript/index.tsx" /* webpackChunkName: "component---src-templates-page-script-index-tsx" */)
}

