/**
 * `analytics.identify([userId], [traits], [options], [callback]);`
 * `analytics.track(event, [properties], [options], [callback]);`
 * `analytics.trackLink(element, event, [properties])`
 * `analytics.trackForm(form, event, [properties])`
 * `analytics.page([name|category], [name], [properties], [options], [callback])`
 * `analytics.group(groupId, [traits], [options], [callback])`
 * `analytics.alias(userId, [previousId], [options], [callback])`
 *
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 */

/**
 * Create a function which once called will return the result of the method from segment if
 * defined or null
 * @param method the name of a segment's method
 */
const wrap =
  (method: keyof Window['analytics']) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): any => {
    // analytics being unavailable does not mean we should not execute the callback
    const argsCallbackIndex = method === 'page' ? 4 : 3;
    const hasTrackCallback =
      method === 'track'
      && args
      && Array.isArray(args)
      && args.length > argsCallbackIndex
      && typeof args[argsCallbackIndex] === 'function';

    const callback = hasTrackCallback ? args[argsCallbackIndex] : null;

    try {
      if (window.analytics && method in window.analytics) {
        /**
         * Segment uses a queue to store the calls to its methods before the script is initialized.
         * @see https://stackoverflow.com/questions/14859058/why-does-the-segment-io-loader-script-push-method-names-args-onto-a-queue-which
         */
        const hasInitialized = window.analytics.initialized;
        const shouldPerformCallbackImmediately = !hasInitialized && hasTrackCallback;
        const argsAdjusted = shouldPerformCallbackImmediately ? args.slice(0, argsCallbackIndex) : args;
        // eslint-disable-next-line @typescript-eslint/ban-types
        const exec = window.analytics[method] as Function;

        const result = exec(...argsAdjusted);
        if (shouldPerformCallbackImmediately) {
          callback();
        }

        return result;
      } else if (hasTrackCallback) {
        callback();
      }

      return null;
    } catch (error) {
      if (hasTrackCallback) {
        callback();
      }

      // ignore trackers' errors
      return null;
    }
  };

export const identify: Window['analytics']['identify'] = wrap('identify');
export const page: Window['analytics']['page'] = wrap('page');
const track: Window['analytics']['track'] = wrap('track');

/**
 * @see https://segment.com/docs/getting-started/04-full-install/#common-properties-to-send-with-a-track-call
 */
export const buildProperties = (
  props: JSONObject = {},
): Record<string, string | number | JSONObject> => {
  return {
    ...props,
    initiator: 'user',
    displayFormat: 'responsive',
    // We’re sending viewport instead of screen width because we want to make
    // analysis based on responsive-display of some pages, and responsive is
    // based on viewport width not screen width (which is fixed no matter the window size)
    viewportWidth: window.innerWidth,
  };
};

export interface TrackedEvent {
  name: string;
  properties?: JSONObject;
}

/**
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 * timeout for optional callback is 300 ms by default
 */
export const logEvent = (
  { name, properties }: TrackedEvent,
  callback?: () => void
): void => {
  const eventProperties = buildProperties(properties);

  if (callback) {
    track(name, eventProperties, {}, callback)
  } else {
    track(name, eventProperties)
  }
};
