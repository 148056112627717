/**
 * Prismic doesn't allow anchor links.
 * So we emulate them with "Web" links like #blabla, which are replaced in Prismic by https://#blabla
 * We parse it and simply remove the https://
 */
const patchAnchorURL = (url?: string) => {
  if (!url) {
    return '';
  }

  return url.startsWith("https://#") ? url.replace("https://", "") : url;
}

export default patchAnchorURL;
