import { Circle, Flex, Text, Badge } from "@chakra-ui/react";
import { ShiIcon } from "src/atoms";
import { Serializer, SerializerSelector } from "../types";
import { applyNbsp, getBadgeColor, getComponentFromSelector, tweakBadgeName } from "../utils";

export const strong: Serializer = ({ content, key, overrideProps }) => (
  <Text as="strong" key={key} fontWeight="medium" {...overrideProps}>
    {applyNbsp(content)}
  </Text>
);

export const span: Serializer = ({ content, key, overrideProps }) => (
  <Text as="span" key={key} {...overrideProps}>
    {applyNbsp(content)}
  </Text>
);

const labelsSelector: SerializerSelector = [
  {
    condition: ({ element }) => element.data?.label === "legend",
    component: ({ key, content, overrideProps }) => (
      <Text
        as="span"
        className="check"
        key={`${key}-${content}`}
        variant="legend"
        sx={{
          "&:not(:last-of-type)": {
            marginRight: "space-4",
          },
        }}
        {...overrideProps}
      >
        <ShiIcon
          name="check"
          color="text-light"
          marginRight="space-8"
          fontSize="font-15"
        />
        {content}
      </Text>
    ),
  },

  {
    condition: ({ element }) => element.data?.label === "checklist",
    component: ({ key, content, overrideProps, children }) => (
      <Flex
        align="baseline"
        key={`${key}-${content}`}
        {...overrideProps}
        as="span"
      >
        <Circle
          marginRight="space-12"
          sx={{
            width: "24px",
            height: "24px",
          }}
          as="span"
        >
          <ShiIcon name="check" boxSize="70%" />
        </Circle>

        <Text as="span" display="block" alignSelf="start">
          {children}
        </Text>
      </Flex>
    ),
  },

  {
    condition: ({ element }) =>
      ['badge-blue', 'badge-green', 'badge-orange', 'badge-red'].includes(element.data?.label),
    component: ({ element, key, content, overrideProps }) => {
      const badge = tweakBadgeName(element.data?.label);
      const badgeColor = getBadgeColor(badge);
      return (
        <Badge
          maxWidth="max-content"
          height="fit-content"
          variant={badgeColor}
          className={badge}
          key={`${key}-${content}`}
          {...overrideProps}
        >
          {content}
        </Badge>
      );
    },
  }
];

export const label: Serializer = (args) =>
  getComponentFromSelector(labelsSelector, args) ?? null;
