/**
 * We should be careful about any string operation as it comes after the sanitization done on the server-side
 * @see https://github.com/cure53/DOMPurify?tab=readme-ov-file#is-there-any-foot-gun-potential
 * 
 * colorChanges is an array of tuples. First element of a tuple is the color to be replaced by the second element
 */
const adjustSvg = (svg: string | undefined, size: number, colorChanges?: [string, string][]) => {
  if (!svg) {
    return '';
  }

  const hasSize = size > 0;

  if (!hasSize) {
    return svg;
  }

  const endSvgTagIndex = svg.indexOf('>');
  if (endSvgTagIndex === -1) {
    return '';
  }

  const firstSubStringTemp = svg.slice(0, endSvgTagIndex);
  const secondSubString = svg.slice(endSvgTagIndex);

  const firstSubString = firstSubStringTemp.replace(/height="\d+"/, `height="${size}"`).replace(/width="\d+"/, `width="${size}"`);
  const svgWithDimensions = `${firstSubString}${secondSubString}`;

  if (!colorChanges || colorChanges.length < 1) {
    return svgWithDimensions;
  }

  const svgFinal = colorChanges.reduce(
    (accumulator: string, colorTuple: [string, string]) => {
      const re = new RegExp(colorTuple[0], 'gi');

      return accumulator.replace(re, colorTuple[1]);
    },
    svgWithDimensions
  );

  return svgFinal;
};

export { adjustSvg };
