import { VisitorContextType } from "./types";

// Production site needs to set explicitly the cookie's domain so the webapp can access it
const COOKIE_SETTINGS =
  window?.location.hostname === "www.shine.fr"
    ? `domain=${process.env.GATSBY_VISITOR_CONTEXT_COOKIE_DOMAIN};secure`
    : "";

const writeCookie = (reducedVisitorContext: Partial<VisitorContextType>) => {
  const expiration = new Date();
  expiration.setDate(expiration.getDate() + 7);
  const cookieValue = encodeURIComponent(JSON.stringify(reducedVisitorContext));
  const cookie = `${
    process.env.GATSBY_VISITOR_CONTEXT_COOKIE_NAME
  }=${cookieValue};expires=${expiration.toUTCString()};path=/;${COOKIE_SETTINGS}`;
  document.cookie = cookie;
};

export default writeCookie;
