import { createContext } from "react";

import { VisitorContextType } from "./types";

const VisitorContext = createContext<VisitorContextType>({
  updateVisitorContext: () => undefined,
  countryCode: "FR",
});

export default VisitorContext;
