import { Divider, Text, Box } from "@chakra-ui/react";
import { NAVBAR_HEIGHT } from "src/slices/Header";
import { Serializer, SerializerSelector } from "../types";
import { getComponentFromSelector } from "../utils";

const paragraphSelector: SerializerSelector = [
  /**
   * Anchor links are represented by paragraphs starting with #
   */
  {
    condition: ({ element }) => element.text?.startsWith("#"),
    component: ({ element }) => (
      <Box
        id={element.text.slice(1)}
        sx={{
          scrollMarginTop: `${NAVBAR_HEIGHT + 48}px`,
        }}
      />
    ),
  },

  /**
   * Dividers are represented by `---`
   */
  {
    condition: ({ element }) => element.text === "---",
    component: ({ key, overrideProps }) => (
      <Divider
        key={key}
        variant="grey"
        size="medium"
        marginBottom="space-16"
        {...overrideProps}
      />
    ),
  },

  /**
   * Empty space between blocks are represented by newlines.
   */
  {
    condition: ({ element }) => element.text === "",
    component: ({ key }) => <Box boxSize="1em" key={key} />,
  },

];

const paragraph: Serializer = (args) =>
  getComponentFromSelector(paragraphSelector, args) ?? (
    <Text
      variant="body"
      key={args.key}
      marginBottom="space-24"
      {...args.overrideProps}
    >
      {args.children}
    </Text>
  );

export default paragraph;
