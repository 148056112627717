import {
  AspectRatio,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import YouTube from "react-youtube";
import { ShiIcon, MotionBox } from "src/atoms";

const HeaderVideo: FC<{ youtubeId: string }> = ({ youtubeId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Center position="absolute" width="100%" height="100%" top={0} left={0}>
      <MotionBox
        animate={{ scale: 1.1 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 2,
        }}
      >
        <IconButton
          backgroundColor="text-primary"
          _hover={{
            backgroundColor: "grey-1",
          }}
          aria-label="Play"
          onClick={() => setIsOpen(true)}
          icon={<ShiIcon name="play" boxSize="40px" />}
          paddingY="unset" //required to override the default padding
          paddingX="unset" //required to override the default padding
          padding="space-24"
          borderRadius="full"
        />
      </MotionBox>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        variant="video-player"
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding="0" borderRadius="md" overflow="hidden" bg="black">
            <AspectRatio width="100%" ratio={16 / 9}>
              <YouTube
                videoId={youtubeId}
                opts={{
                  width: "100%",
                  height: "100%",
                  playerVars: {
                    autoplay: 1,
                  },
                }}
              />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default HeaderVideo;
