export enum PricingPlan {
  SHINE_BASIC = "Shine Basic",
  SHINE_PLUS = "Shine Plus",
  SHINE_PRO = "Shine Pro",
  SHINE_BUSINESS = "Shine Business",
}

export interface ClickInfo {
  platform?: 'web-desktop' | 'web-mobile',
  label?: string,
  price?: number,
  title?: string,
  block?: 'block_plans_presentation' | 'block_pricing_table' | 'site_navbar',
  subtitle?: string,
  isReferralFlow?: boolean,
  isReferralValid?: boolean,
  fromLocation?: Location
};

// If the page starts with any of the paths, it belongs to pageTemplate
export interface PageTemplateMatcher {
  paths: string[];
  pageTemplate: string;
};
