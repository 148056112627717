import { Button, ButtonProps, ComponentWithAs, chakra } from "@chakra-ui/react";

const ShiButton: ComponentWithAs<
  "button",
  ButtonProps & {
    hasArrow?: boolean;
  }
> = ({ children, hasArrow = true, ...props }) => (
  <Button
    sx={{
      ":hover": {
        ".element-cta-icon": {
          transform: (theme) => `translateX(${theme.space[1]})`,
        },
      },
    }}
    {...props}
  >
    {children}

    {hasArrow && (
      <chakra.span
        transition="transform .2s ease-out"
        marginLeft="space-8"
        className="element-cta-icon"
      >
        →
      </chakra.span>
    )}
  </Button>
);

export default ShiButton;
